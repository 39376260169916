import Plausible from 'plausible-tracker';

// Highlighting for code blocks
import 'prismjs/themes/prism.css';

// Plausible init
const plausible = Plausible({
  domain: 'adminmate.io'
});

// This tracks the current page view and all future ones as well
plausible.enableAutoPageviews();
